<template>
    <section>
      <b-card>
        <div>
          <!-- table -->
          <vue-good-table
            styleClass="vgt-table table-custom-style striped"
            :line-numbers="false"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :rows="rows"
            :columns="columns"
            :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: [{ field: 'created_at', type: 'desc' }],
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- payment date -->
              <template v-if="props?.column?.field === 'format_created_at'">
                <div v-if="props.row.created_at">
                  {{ formatDateTime(props.row.created_at) }}
                </div>
              </template>
  
              <template v-if="props?.column?.field === 'format_amount'">
                <div v-if="props.row.amount_paid">
                  <div>
                    {{ props?.row?.currency?.toUpperCase() }}
                    {{ props.row.amount_paid }}
                  </div>
                </div>
              </template>
  
              <!-- next payment date -->
              <template
                v-if="props?.column?.field === 'format_next_payment_date'"
              >
                <div v-if="props.row.next_payment_date">
                  {{ formatDateTime(props.row.next_payment_date) }}
                </div>
              </template>
  
              <template v-if="props?.column?.field === 'format_status'">
                <b-badge :variant="getStatusVariant(props.row.payment_status)">
                  {{ getStatusText(props.row.payment_status) }}
              </b-badge>
              </template>
            </template>
  
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100', '500']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-card>
    </section>
  </template>
  
  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BProgress,
    BProgressBar,
    BCardBody,
    BCardText,
  } from "bootstrap-vue";
  import { VueGoodTable } from "vue-good-table";
  import Ripple from "vue-ripple-directive";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
  
  export default {
    name: "CompanySubscriptionHistory",
    components: {
      UserAssignPolicyForm,
      BForm,
      BButton,
      BCard,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      ValidationProvider,
      ValidationObserver,
      BModal,
      BSpinner,
      BRow,
      BCol,
      VBTooltip,
      BProgress,
      BProgressBar,
      BCardBody,
      BCardText,
  
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {  
        companyId: "",
        paymentMethod: null,
        subscriptionExpireDate: "",
        pageLength: 10,
        columns: [
          {
            label: "Payment Date",
            field: "format_created_at",
            sortable: false,
          },
          {
            label: "Amount",
            field: "format_amount",
            sortable: false,
          },
          {
            label: "Status",
            field: "format_status",
            sortable: false,
          },
          {
            label: "Next Payment Date",
            field: "format_next_payment_date",
            sortable: false,
          },
        ],
        rows: [],
        searchTerm: "",
        delayTimer: null,
        isLoading: false,
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: [
            { field: "id", type: "desc" },
            { field: "created_at", type: "desc" },
          ],
          page: 1,
          perPage: 10,
        },
      };
    },
  
    async created() {
      this.companyId = this.$route.params.id;
      this.paymentMethod = this.$route.params.payment_method;
      try {
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  
    methods: {
      capitalizeFirstLetter(string) {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      formatDateTime(value) {
        if (value) {
          return this.$moment(value).format("MMM Do YYYY");
        }
      },
  
      formatSubscriptionExpiredDateTime(value) {
        if (value) {
          return this.$moment(value).subtract(1, 'days').format("MMM Do YYYY");
        }
      },
  
      async getPaymentHistory(params) {
        return await this.$api.get("api/super-admin/subscription-history", {
          params: {
            company_id: params.company_id,
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            payment_method: params.payment_method,
          },
        });
      },
  
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
  
      onPageChange(params) {
        this.updateParams({ page: params.currentPage });
        this.loadItems();
      },
  
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
  
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.loadItems();
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: params,
        });
        this.loadItems();
      },
  
      async loadItems() {
        try {
          const paymentHistories = await this.getPaymentHistory({
            company_id: this.companyId,
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            payment_method: this.paymentMethod,
          });
  
          const data = paymentHistories?.data?.data;
          const meta = paymentHistories?.data?.meta;
          this.subscriptionExpireDate = meta?.subscriptionExpired?.next_payment_date
  
          this.totalRecords = meta?.pagination?.total;
          this.rows = data;

        } catch (error) {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "BellIcon",
              variant: "warning",
              text: error?.response?.data?.message,
            },
          });
        }
      },
  
      getStatusVariant(status) {
          switch(status) {
            case 'complete':
              return 'light-success'; // Green for completion
            case 'on_payment':
                return 'light-success'; // Green for payment in progress
            case 'pause':
                return 'light-danger'; // Red for paused state
            case 'resume':
                return 'light-primary'; // Blue for resumed state
            case 'cancel':
                return 'light-danger'; // Red for cancellation
            case 'trial':
                return 'light-warning';;
              default:
                  return 'light-secondary'; // default color
          }
      },
      getStatusText(status) {
          switch(status) {
              case 'complete':
                  return 'Complete';
              case 'on_payment':
                  return 'On Payment';
              case 'resume':
                  return 'Resume';
              case 'cancel':
                  return 'Inactive';
              case 'pause':
                  return 'Paused';
              case 'trial':
                  return 'Trial';
              default:
                  return 'Unknown';
          }
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  
  .required-label label::after {
    content: " *";
    color: red;
  }
  </style>